/* eslint-disable */
const config = {
  SITE_URL: process.env.REACT_APP_URL,
  APP_URL: process.env.REACT_APP_APP_URL,
  // API
  API: process.env.REACT_APP_API,
  stage: process.env.REACT_APP_STAGE,
  region: process.env.REACT_APP_REGION,
};

export default {
  ...config,
};
